import React from 'react';
import styles from './index.module.css';
import Logo from '../../../assets/images/Logo_white_plain.svg';
import ReactDOM from 'react-dom';
import Footer from '../../Register/Footer';
import withHooks, { useEffect } from 'react-with-hooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const useEscapeKey = (cb) => {
  const onKeyup = (event) => {
    return (event.key === 'Escape' || event.keyCode === 27) && cb();
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyup, true);
    return function cleanup() {
      window.removeEventListener('keyup', onKeyup, true);
    };
  });
};

const usePreventBodyScroll = () => {
  useEffect(() => {
    const el =
      document.querySelector('#modal-scroll') ||
      document.querySelector(`.${styles.ModalBody}`);
    disableBodyScroll(el);
    // document.body.classList.add(styles.modalOpenBody);
    // document.documentElement.classList.add(styles.modalOpenBody);
    return () => {
      enableBodyScroll(el);
      // document.body.classList.remove(styles.modalOpenBody);
      // document.documentElement.classList.remove(styles.modalOpenBody);
    };
  });
};

const Modal = withHooks(
  ({
    children,
    title,
    backgroundStyle,
    onClose = () => {},
    showLogo = true,
    showClose = true,
    modalContentStyle,
    showFooter = true,
    style = {},
  }) => {
    usePreventBodyScroll();
    useEscapeKey(onClose);

    return ReactDOM.createPortal(
      <div className={styles.Modal} style={style}>
        <div className={`${styles.ModalBody} ${backgroundStyle}`}>
          {showClose && (
            <div onClick={onClose}>
              <img
                className={styles.ModalCloseBtn}
                alt="close"
                src={require('./x-icon.svg')}
              />
            </div>
          )}

          <div className={`${styles.ModalContent} ${modalContentStyle}`}>
            <div className={styles.ModalHeader}>
              {showLogo && (
                <img
                  className={styles.ModalLogo}
                  src={Logo}
                  alt="Schmodel Log"
                />
              )}
              {title && <h2 className={styles.h2}>{title}</h2>}
            </div>
            {children}
            {showFooter && (
              <React.Fragment>
                <div style={{ height: 65 }} />
                <div
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    zIndex: 1000000000,
                  }}
                >
                  <Footer replace={true} className={styles.BlackBackground} />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>,
      document.getElementById('modal-root')
    );
  }
);

export default Modal;
