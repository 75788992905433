import React from "react";

import gql from "graphql-tag";
import { useMutation } from '@apollo/client';
import { adopt } from "react-adopt";

import { connect } from "react-redux";
import { destroy, reduxForm } from "redux-form";

export const BRAND_FRAGMENT = gql`
  fragment BrandFragment on Brand {
    _id
    website
    instagramUsername
    contactName
    contactPhone {
      number
    }
    contactEmail
    resources
  }
`;

export const BRAND = gql`
  query brand($input: BrandInput) {
    brand(input: $input) {
      ...BrandFragment
    }
  }
  ${BRAND_FRAGMENT}
`;

export const CREATE_BRAND = gql`
  mutation createBrand($input: BrandInput) {
    createBrand(input: $input) {
      ...BrandFragment
    }
  }
  ${BRAND_FRAGMENT}
`;

const Form = reduxForm({
  form: "registerBrand",
})((props) => <React.Fragment>{props.render(props)}</React.Fragment>);

let index = adopt({
  createBrand: ({ render }) => {
    const [createBrandMutation, { data, loading, error }] = useMutation(CREATE_BRAND);
    return render(createBrandMutation, { data, loading, error });
  },
  form: ({ ...props }) => {
    return <Form {...props} />;
  },
});

index = connect(
  void 0,
  { destroy },
)(index);

index.format = (obj) => {
  for (var property in obj) {
    if (typeof obj[property] === "object") {
      delete obj.property;
      let newJsonData = index.format(obj[property]);
      obj[property] = newJsonData;
    } else {
      if (property === "__typename") {
        delete obj[property];
      }
    }
  }
  return obj;
};

export default index;
