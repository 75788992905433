import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "./index.module.css";
import cn from "classnames";

class Footer extends Component {
  render() {
    const { replace } = this.props;
    return (
      <div className={cn(style.Footer, this.props.className)}>
        <Link replace={replace} to={"/contact-us"}>
          <button className={`${style.FooterButton} ${style.ContactUs}`}>
            Contact Us
          </button>
        </Link>
        {"/"}
        <Link replace={replace} to={"/terms-and-conditions"}>
          <button className={style.FooterButton}>Terms & Conditions</button>
        </Link>
        {"/"}
        <Link replace={replace} to={"/privacy-notice"}>
          <button className={style.FooterButton}>Privacy Notice</button>
        </Link>
      </div>
    );
  }
}

export default Footer;
