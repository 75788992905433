const admin = {
  initial: "search",
  meta: { path: "/admin" },
  context: {},
  states: {
    login: {
      meta: { path: "/admin/login" }
    },
    search: {},
    talents: {
      meta: { path: "/admin/talents" }
    },
    results: {
      meta: { path: "/admin/results" }
    },
    model: { meta: { path: "/admin/model/:id" } }
  }
};

export default admin;
