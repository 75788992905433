import React from "react";
import { Field } from "redux-form";

import { Row } from "../../utils/InputGroup";
import { Checkbox } from "../../utils/Checkbox-v2";

import stylesButton from "../../utils/css/button.module.css";
import styles from "../styles.module.css";
import Success from "../Success";
import { ReactComponent as Warning } from "src/assets/icons/warning.svg";

const resources = [
  "Influencers / Content Creators",
  "Fashion Models",
  "Photographers / Videographers",
  "Hair & Makeup Artists",
  "Art directors / Producers",
  "Studio / Creative space rental",
];

const SelectedResources = (props) => (
  <React.Fragment>
    {props.resources.map((resource) => (
      <Row
        handleChecked={() => props.handleCheckedResources(resource)}
        key={resource}
      >
        <Checkbox
          label={resource}
          isChecked={props.checkedResources.includes(resource)}
        />
      </Row>
    ))}
  </React.Fragment>
);

const warningDetails = "Please select the resource(s) you need!";
export default class StepSecond extends React.Component {
  render() {
    return (
      <div>
        {this.props.isSuccess ? (
          <Success openModal={this.props.openModal} />
        ) : (
          <div className={styles.step_second}>
            <p className={styles.title}>Select the resource(s) you need</p>
            <form onSubmit={this.props.handleSubmit}>
              <Field
                name="resources"
                component={SelectedResources}
                handleCheckedResources={this.props.handleCheckedResources}
                resources={resources}
                checkedResources={this.props.checkedResources}
              />
              <div className={styles.error_form}>
                {this.props.checkedResources &&
                  this.props.checkedResources.length === 0 &&
                  this.props.isError && (
                    <>
                      <span className={styles.warning_icon}>
                        <Warning />
                      </span>
                      <p>{warningDetails.toUpperCase()}</p>
                    </>
                  )}
              </div>
              <div className="flex justify-between">
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_back
                  }`}
                  onClick={this.props.openStepSecond}
                >
                  Back
                </button>
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_submit
                  }`}
                  type="submit"
                  onClick={this.props.setIsError}
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}
