import React from "react";
import "./sticky.css";

class StickyHeader extends React.Component {
  scrollCallBack;
  sticky;
  constructor(props) {
    super(props);
    this.stickyRef = React.createRef(null);
  }

  onScroll = () => {
    if (!this.stickyRef.current) return;
    if (window.pageYOffset > this.sticky) {
      this.stickyRef.current.classList.add("fixed");
    } else {
      this.stickyRef.current.classList.remove("fixed");
    }
  };

  componentDidMount() {
    this.sticky = this.stickyRef.current.offsetTop;
    this.scrollCallBack = window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    if (this.scrollCallBack) {
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  render() {
    return (
      <div className="sticky" ref={this.stickyRef}>
        {this.props.children}
      </div>
    );
  }
}

export default StickyHeader;
