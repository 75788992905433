import React from "react";

import girlsinleatherBg from "../../../assets/images/girlsinleather-bg.png";

import Step from "../Step";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";

class Success extends React.Component {
  handleBack() {
    window.location.href = window.location.origin;
  }
  render() {
    return (
      <Step backgroundImage={girlsinleatherBg} classNames={styles.success}>
        <p className={styles.title}>All done!</p>
        <p className={styles.sub_title}>
          Thanks for sharing your info. <br /> Our app is coming soon!
          <br />
          <br />
          Email us at
          <br /> <b>hello@schmodel.com</b>
          <br />
          for more info.
        </p>
        <br />
        <a
          className={`${stylesButton.button} ${stylesButton.button_back} ${
            styles.back_home
          }`}
          href="/"
        >
          Back home
        </a>
      </Step>
    );
  }
}

export default Success;
