import React from "react";

import item1 from "../../../../assets/images/item_5_1.png";
import item2 from "../../../../assets/images/item_5_2.png";
import item3 from "../../../../assets/images/bg_5_2.png";

import styles from "./styles.module.css";

const TheStudioSpaceMobile = () => {
  return (
    <div className={styles.container_mobile}>
      <div className={styles.the_studio_space_mb}>
        <p className={styles.title_mb}>The Studio Space</p>
        <p className={styles.decs_mb}>
          Model search and scouting events
          <br />
          Career coaching and training workshops
        </p>
      </div>

      <div className={styles.desc_block_left_mb}>
        We can provide the talent, creatives
        <br /> and shoot location for a fixed fee
      </div>
      <div className={styles.block_mb}>
        BRAND LAUNCHES AND
        <br /> CAMPAIGN PARTIES
      </div>
      <div className={styles.item_img_mb}>
        <img src={item1} alt="" className={styles.img1_mb} />
        <img src={item2} alt="" className={styles.img2_mb} />
      </div>
      <div className={styles.block_2_mb}>
        Exclusive networking events <br /> for members
        <img src={item3} alt="" className={styles.img_block_2_mb} />
      </div>
      <div className={styles.block_4_mb}>
        SCHMODEL STUDIOS CAN BE USED <br />
        BY TALENT, BRANDS AND CREATIVES <br />
        WHEN NOT IN PRODUCTION
      </div>
    </div>
  );
};

export default TheStudioSpaceMobile;
