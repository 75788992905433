import React from "react";

import logo from "../../../assets/images/fixed_header_logo.png";
import logoMobile from "../../../assets/images/logo-with-slogan-mobile.png";
import showmethemoney from "../../../assets/images/showmethemoney.png";
import itsmymoney from "../../../assets/images/itsmymoney.png";
import icanearn from "../../../assets/images/icanearn.png";
import letter from "../../../assets/images/letter.png";
import mobileletter from "../../../assets/images/mobileletter.png";
import westronglypromote from "../../../assets/images/westronglypromote.png";
import girlsinleather from "../../../assets/images/girlsinleather.png";
import everymodel from "../../../assets/images/everymodel.png";
import styles from "./styles.module.css";

import StickyHeader from "./StickyHeader";

export default class Main extends React.Component {
  render() {
    return (
      <>
        <StickyHeader>
          <div className={`${styles.header_link} `}>
            <button
              className={`${styles.header_link_styles} ${
                styles.header_link_child_1
              }`}
              onClick={this.props.handleScrollBottom}
            >
              I AM TALENT
            </button>
            <button
              className={styles.header_link_styles}
              onClick={this.props.handleOpenBrandSignup}
            >
              I'M A BRAND
            </button>
          </div>
        </StickyHeader>

        <div className={styles.main}>
          <div className={`${styles.banner} ${styles.first_banner}`}>
            <div className={styles.is_desktop}>
              <div className={styles.header_logo_wapper}>
                <div className={styles.header_logo}>
                  <img src={logo} alt="error" className={styles.for_desktop} />
                </div>
                <div className={styles.header_cylinder} />
              </div>
            </div>

            <div className={styles.is_mobile}>
              <div className={styles.header_cylinder}>
                <div className={styles.header_logo}>
                  <img
                    src={logoMobile}
                    alt="error"
                    className={styles.for_mobile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.banner} ${styles.second_banner}`}>
            <div className={styles.content_shoot_to_kill}>
              <div className={styles.content_shoot_to_kill_div}>
                <h1 className={styles.content_shoot_to_kill_h1}>
                  Shoot to kill
                </h1>
                <p className={styles.content_shoot_to_kill_p}>
                  My face. My career.
                  <br />I work on my own terms.
                </p>
              </div>
            </div>
            <div className={styles.shoot_to_kills}>
              <div className={styles.shoot_to_kills_div}>
                <img src={showmethemoney} alt="error" />
                <p>
                  We don’t charge you fees.
                  <br /> You don’t pay us.
                  <br />
                  Enough said.
                </p>
              </div>
              <div className={styles.shoot_to_kills_div}>
                <img src={itsmymoney} alt="error" />
                <p className={styles.shoot_to_kills_p}>
                  Get your money in 15 days. <br />
                  No chasing. No yelling.
                  <br />
                  No excuses.
                </p>
              </div>
              <div className={styles.shoot_to_kills_div}>
                <img src={icanearn} alt="error" />
                <p>
                  Bring your friends. <br />
                  Watch them work. <br />
                  Get paid for it.
                </p>
              </div>
            </div>
            <div className={styles.second_banner_bg1} />
          </div>

          <div className={`container banner mx-auto ${styles.third_banner}`}>
            <img src={letter} className={styles.third_banner_img} alt="error" />
            <img
              src={mobileletter}
              className={styles.third_banner_img_mobile}
              alt="error"
            />
          </div>

          <div className={styles.fourth_banner}>
            <div className={styles.fourth_banner_wrap_content}>
              <div className={styles.fourth_banner_wrap_content_to_content}>
                <span
                  className={styles.fourth_banner_wrap_content_to_content_title}
                >
                  Hello Talent
                </span>
                <p className="text-3xl">This is who we are</p>
                <div>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_1
                    }
                  >
                    We don’t charge you a commission
                  </p>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_2
                    }
                  >
                    You set your rates
                    <br />
                    The client pays us a fee
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_1
                    }
                  >
                    We guarantee payment in 15 days
                  </p>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_2
                    }
                  >
                    Let us deal with the client <br />
                    Just finish the job and we’ll do the rest
                  </p>
                </div>
                <div>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_1
                    }
                  >
                    Bring a friend and earn more
                  </p>
                  <p
                    className={
                      styles.fourth_banner_wrap_content_to_content_div_p_child_2
                    }
                  >
                    Become your own agency
                    <br />
                    Refer friends and earn every time they do
                  </p>
                </div>
              </div>
              <div className={styles.fourth_banner_wrap_images}>
                <div className={styles.fourth_banner_wrap_images_first_block}>
                  <div
                    className={
                      styles.fourth_banner_wrap_images_first_block_block_1
                    }
                  >
                    <img src={westronglypromote} alt="error" />
                    <div
                      className={
                        styles.fourth_banner_wrap_images_first_block_block_1_div
                      }
                    >
                      <div
                        className={`${
                          styles.fourth_banner_wrap_images_first_block_block_1_img
                        } ${styles.for_mobile}`}
                      >
                        <img src={everymodel} alt="error" />
                      </div>
                      <span className={styles.we_strongly_desktop}>
                        WE STRONGLY PROMOTE TRANSPARENCY AND EQUALITY
                      </span>
                      <span className={styles.we_strongly_mobile}>
                        WE STRONGLY PROMOTE
                        <br />
                        TRANSPARENCY AND EQUALITY
                      </span>
                    </div>
                    <div className={styles.for_mobile}>
                      <p
                        className={
                          styles.fourth_banner_wrap_images_first_block_block_2_p
                        }
                      >
                        <p class={styles.fair_access_mobile}>
                          Fair access to ALL
                        </p>{" "}
                        <p class={styles.available_mobile}>available jobs</p>
                      </p>
                    </div>
                  </div>

                  <div
                    className={`${
                      styles.fourth_banner_wrap_images_first_block_block_2
                    } ${styles.for_desktop}`}
                  >
                    <img
                      src={everymodel}
                      className={`${
                        styles.fourth_banner_wrap_images_first_block_block_2_img
                      }`}
                      alt="error"
                    />
                    <p
                      className={
                        styles.fourth_banner_wrap_images_first_block_block_2_p
                      }
                    >
                      Fair access
                      <br /> to ALL
                      <br /> available jobs
                    </p>
                  </div>
                </div>
                <div className={styles.fourth_banner_wrap_images_second_block}>
                  <img
                    src={girlsinleather}
                    className={styles.for_desktop}
                    alt="error"
                  />
                  <div
                    className={`${styles.for_mobile} ${
                      styles.fourth_banner_wrap_images_second_block_bg
                    }`}
                  />
                  <div
                    className={`${
                      styles.fourth_banner_wrap_images_second_block_content_left_div_child_1
                    } ${styles.for_mobile}`}
                  >
                    Our “bring-a-friend” system creates more earnings for talent
                  </div>
                  <div
                    className={
                      styles.fourth_banner_wrap_images_second_block_content_left
                    }
                  >
                    <div
                      className={`${
                        styles.fourth_banner_wrap_images_second_block_content_left_div_child_1
                      } ${styles.for_desktop}`}
                    >
                      Our “bring-a-friend” system creates more earnings for
                      talent
                    </div>
                    <div
                      className={`${
                        styles.fourth_banner_wrap_images_second_block_content_left_div_child_2
                      } ${
                        styles.fourth_banner_wrap_images_second_block_content_left_div_child_2_desktop
                      }`}
                    >
                      WE BELIEVE THAT TALENT SHOULD BE PROMOTED, NOT EXTORTED
                    </div>
                    <div
                      className={`${
                        styles.fourth_banner_wrap_images_second_block_content_left_div_child_2
                      } ${
                        styles.fourth_banner_wrap_images_second_block_content_left_div_child_2_mobil
                      }`}
                    >
                      <span className={styles.we_believe_that_talent_mobile}>
                        WE BELIEVE THAT TALENT SHOULD
                        <br /> BE PROMOTED, NOT EXTORTED
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
