// const isMobile = () => {
//   return window.innerWidth <= 1000;
// };

const boot = {
  on: {
    // "": [{ target: "whoAreWe", cond: () => isMobile() }, { target: "details" }],
    "": [{ target: "details" }],
  },
};

const whoAreWe = {
  meta: { path: "/who-are-we" },
  on: {
    NEXT: {
      target: "details",
    },
  },
};

const details = {
  meta: { path: "/register", step: 1 },
  on: {
    NEXT: {
      target: "skills",
    },
  },
};

const skills = {
  meta: { path: "/register/skills", step: 2 },
  on: {
    NEXT: {
      target: "location",
    },
    BACK: {
      target: "details",
    },
  },
};

const location = {
  meta: { path: "/register/location", step: 3 },
  on: {
    NEXT: "instagram",
    BACK: "skills",
  },
};

const instagram = {
  meta: { path: "/register/instagram", step: 4 },
  initial: "connectAccount",
  states: {
    connectAccount: {
      on: {
        NO_ACCOUNT: "noAccount",
      },
    },
    noAccount: {
      meta: { path: "/register/no-account" },
      on: {
        NO_ACCOUNT: "noAccountFinal",
      },
    },
    noAccountFinal: {
      meta: { path: "/register/no-account-final" },
    },
  },
  on: {
    FAIL: {
      target: "fail",
    },
    CONNECT: {
      target: "selectInstagramPhotos",
    },
    BACK: "location",
  },
};

const selectInstagramPhotos = {
  meta: { path: "/register/instagram-photos", step: 5 },

  on: {
    LOAD_MORE: "selectInstagramPhotos",
    NEXT: "verification",
    BACK: "instagram",
  },
};

const verification = {
  meta: { path: "/register/verification", step: 6 },
  on: {
    NEXT: "complete",
    BACK: "details",
  },
};

const verifyEmail = {
  meta: { path: "/register/verify-email/:code", step: 3 },
};

const complete = {
  meta: { path: "/register/complete" },
};

const fail = {
  meta: { path: "/register/fail" },
};

const register = {
  initial: "boot",
  context: { images: [] },
  states: {
    boot,
    details,
    skills,
    location,
    instagram,
    selectInstagramPhotos,
    verification,
    verifyEmail,
    complete,
    fail,
  },
};

export default register;
