import React from "react";
import { adopt } from "react-adopt";
import { XStateRouter, RouterMachine, MachineState } from "xstate-react-router";

export default class StateMachine extends React.Component {
  render() {
    return (
      <XStateRouter config={this.props.machine}>
        {this.props.children}
      </XStateRouter>
    );
  }
}

StateMachine.Consumer = adopt({
  current: ({ render }) => <MachineState>{render}</MachineState>,
  service: ({ render }) => <RouterMachine>{render}</RouterMachine>
});

export const Consumer = StateMachine.Consumer;

StateMachine.State = ({ children, is }) => (
  <StateMachine.Consumer>
    {({ current }) => (current.matches(is) ? children : null)}
  </StateMachine.Consumer>
);

export const State = StateMachine.State;
