import React from "react";

import StateMachine from "../utils/StateMachine";

import Home from "../Home";
import StepSecond from "./StepSecond";
import StepThird from "../TalentSignup/StepThird";
import StepFourth from "../TalentSignup/StepFourth";
import StepFive from "../TalentSignup/StepFive";
import StepConfirmation from "../TalentSignup/StepConfirmation";
import Success from "../TalentSignup/Success";

export default class TalentSignup extends React.Component {
  render() {
    return (
      <StateMachine.State is="register">
        <StateMachine.Consumer>
          {({ service, current }) => (
            <React.Fragment>
              <StateMachine.State is="register.details">
                <Home />
              </StateMachine.State>
              <StateMachine.State is="register.skills">
                <StepSecond />
              </StateMachine.State>
              <StateMachine.State is="register.location">
                <StepThird />
              </StateMachine.State>
              <StateMachine.State is="register.instagram">
                <StepFourth />
              </StateMachine.State>
              <StateMachine.State is="register.selectInstagramPhotos">
                <StepFive />
              </StateMachine.State>
              <StateMachine.State is="register.verification">
                <StepConfirmation />
              </StateMachine.State>
              <StateMachine.State is="register.complete">
                <Success />
              </StateMachine.State>
            </React.Fragment>
          )}
        </StateMachine.Consumer>
      </StateMachine.State>
    );
  }
}
