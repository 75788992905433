import React from "react";

import blondeleftBackground from "../../../assets/images/blondeleft.png";
import blondeleftBackgroundMobile from "../../../assets/images/blonde_mobile.png";
import { ReactComponent as Warning } from "src/assets/icons/warning.svg";

import Step from "../Step";
import { Row } from "../../utils/InputGroup";
import { Checkbox } from "../../utils/Checkbox-v2";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";
import WithUserForm from "../../Register/WithUserForm";
import { Field } from "redux-form";
import PlacesAutocomplete from "../../Field/PlacesAutocomplete";
import withHooks, { useEffect } from "react-with-hooks";

const countries = [
  "UAE",
  "UK",
  "EU",
  "USA",
  "Canada",
  "Hong Kong",
  "Australia",
  "Mexico",
];

const required = (value) =>
  value ? undefined : "Please enter your home-base city!";

const PlacesAutocompleteInput = withHooks(({ input, ...props }) => {
  useEffect(() => {
    if (props.isBlur) {
      props.handleCheckError(props.meta.error);
    }
  }, [props.meta.error, props.isBlur]);
  return (
    <div className={styles.wrap_autocomplete}>
      <PlacesAutocomplete {...input} {...props} />
      {!input.value?.geonamesId && <label>*</label>}
    </div>
  );
});

const SelectOptions = withHooks(({ input, ...props }) => {
  useEffect(() => {
    props.getCountries(input.value);
  }, [input.value]);

  const checkCountry = (country) => {
    const checkedCountries = [...props.checkedCountries];
    if (checkedCountries.includes(country)) {
      const index = checkedCountries.indexOf(country);
      checkedCountries.splice(index, 1);
    } else {
      checkedCountries.push(country);
    }
    props.handleCheckedCountries(checkedCountries);
    input.onChange(checkedCountries);
  }
  return (
    <React.Fragment>
      {props.countries.map(
        (country, index) =>
          index % 2 === 0 && (
            <div
              className={`grid grid-cols-2 ${styles.location_rows}`}
              key={country}
            >
              <Row
                handleChecked={() =>
                    checkCountry(props.countries[index])
                }
              >
                <Checkbox
                  isChecked={props.checkedCountries.includes(
                    props.countries[index]
                  )}
                  label={props.countries[index]}
                />
              </Row>
              <Row
                handleChecked={() =>
                    checkCountry(props.countries[index + 1])
                }
              >
                <Checkbox
                  isChecked={props.checkedCountries.includes(
                    props.countries[index + 1]
                  )}
                  label={props.countries[index + 1]}
                />
              </Row>
            </div>
          )
      )}
    </React.Fragment>
  );
});

class StepThird extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedCountries: [],
      error: "",
      isBlur: false,
      homeCityGeonamesId: ""
    };
  }

  getCountriesFromLocalStorage = (countries) => {
    let listCountry = [];
    if (countries && countries.length > 0) {
      listCountry = listCountry.concat(countries);
    }
    this.setState({
      checkedCountries: listCountry,
    });
  };

  handleCheckedCountries = (checkedCountries) => {
    this.setState({
      checkedCountries: checkedCountries,
    });
  };

  handleCheckError = (error) => {
    this.setState({ error: error });
  };

  handleHomeCityGeonamesId = (value) => {
    console.log("Setting state:", value);
    this.setState({homeCityGeonamesId: value});
  };

  async onSubmit(values, _, props) {
    if (!values.homeCity || !values.homeCity.geonamesId) {
      this.handleCheckError("Please enter your home-base city!");
      return;
    }
    values = WithUserForm.formatUserLocationsInput(values);
    await props.updateUserLocations({
      variables: { input: values },
    });
    return props.machine.service.send({
      type: "NEXT",
    });
  }

  render() {
    return (
      <Step
        backgroundImage={
          window.innerWidth > 500
            ? blondeleftBackground
            : blondeleftBackgroundMobile
        }
        classNames={styles.step_third}
      >
        <p className={styles.title}>Where are you based?</p>
        <WithUserForm
          asyncValidate={this.asyncValidate}
          asyncBlurFields={["homeCity.name"]}
          onSubmit={(values, _, props) => this.onSubmit(values, _, props, this)}
        >
          {({
            form: { handleSubmit, invalid, pristine, submitting },
            machine,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={`${styles.form_step_third} ${
                styles.form_third_padding
              }`}
            >
              <Field
                component={PlacesAutocompleteInput}
                placeholder="Enter your home-base city"
                className={styles.places_auto_input}
                styles_menu={styles.menu}
                name="homeCity"
                handleCheckError={this.handleCheckError}
                onBlur={() => this.setState({ isBlur: true })}
                isBlur={this.state.isBlur}
                handleHomeCityGeonamesId={this.handleHomeCityGeonamesId}
              />
              <p className={styles.sub_title_step_third}>
                Where else are you allowed to work?
              </p>
              <Field
                name="locations"
                component={SelectOptions}
                getCountries={this.getCountriesFromLocalStorage}
                handleCheckedCountries={this.handleCheckedCountries}
                countries={countries}
                checkedCountries={this.state.checkedCountries}
              />
              <div className={styles.error_form}>
                {this.state.error && (
                  <>
                    <span className={styles.warning_icon}>
                      <Warning />
                    </span>
                    <p>{this.state.error}</p>
                  </>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_back
                  }`}
                  onClick={() => machine.service.send("BACK")}
                >
                  Back
                </button>
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_submit
                  } ${((invalid && pristine) || invalid || submitting) &&
                    styles.disabled_btn}`}
                  disabled={(invalid && pristine) || invalid || submitting}
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </form>
          )}
        </WithUserForm>
      </Step>
    );
  }
}

export default StepThird;
