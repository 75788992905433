import React from "react";

import styles from "./styles.module.css";

export default class InputGroup extends React.Component {
  render() {
    return (
      <div
        className={`${this.props.classNames} ${styles.input_group}`}
        onChange={this.props.onChange}
        {...this.props}
      >
        {this.props.children}
      </div>
    );
  }
}

export const Row = (props) => {
  return (
    <div onClick={props.handleChecked} className={`${styles.row}`}>
      {props.children}
    </div>
  );
};
