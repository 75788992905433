import React from "react";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";

export default class Success extends React.Component {
  render() {
    return (
      <div className={styles.success}>
        <p className={styles.title}>Success!</p>
        <div className={styles.wrap_success_info}>
          <p>
            Thanks for sharing your info. Now let’s create something special.
          </p>
          <p>
            Email us at
            <br />{" "}
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              Brands@schmodel.com
            </span>
            <br /> to get started.
          </p>
          <p>xoxo</p>
          <button
            className={`${stylesButton.button} ${stylesButton.button_submit}`}
            onClick={this.props.openModal}
          >
            Got it
          </button>
        </div>
      </div>
    );
  }
}
