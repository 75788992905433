import { isValidPhoneNumber } from 'react-phone-number-input';

export const required = (value) =>
  value || typeof value === 'number'
    ? undefined
    : 'All fields are required, darling!';

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
  value &&
  !/^[A-Z0-9._%+-]+@[A-Z0-9][A-Z0-9.-]{0,61}[A-Z0-9]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address, darling!'
    : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters.'
    : undefined;

export const phoneNumber = (value) =>
  value && !isValidPhoneNumber(value)
    ? 'Please check the phone number format :)'
    : undefined;

export const nonSpecialChar = (value) =>
  value && !/^[a-zA-Z0-9_ ]{1,255}$/i.test(value)
    ? 'Sorry hun, symbols are not allowed.'
    : undefined;

export const nonStartWithSpace = (value) =>
  value && /^[\s]{1,255}$/i.test(value)
    ? 'Sorry hun, spaces are not allowed.'
    : undefined;

export const contactFormRequired = (value) =>
  value || typeof value === 'number' ? undefined : 'Please write us a message.';

export const contactFormEmailRequired = (value) =>
  value || typeof value === 'number'
    ? undefined
    : 'Please provide your email address so we can respond to you.';
