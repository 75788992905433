import React from "react";

import styles from "./styles.module.css";

export default class Modal extends React.Component {
  render() {
    return (
      <div
        className={`${styles.modal} ${
          this.props.isOpenModal ? "block" : "hidden"
        }`}
      >
        <div className={styles.wrap_modal_content}>
          <div className={styles.modal_content}>
            <img
              className={styles.close_icon}
              alt="close"
              src={require("./x-icon.svg")}
              onClick={this.props.openModal}
            />
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
