import React from "react";
import { Link } from "react-router-dom";
import withHooks, { useState } from "react-with-hooks";

import logo from "../../../assets/images/logo_no_tagline.svg";
import StepFirst from "../../TalentSignup/StepFirst";

import styles from "./styles.module.css";

const useLocalStorage = (key, initialValue) => {
  const [item, setValue] = useState(() => {
    const value = JSON.parse(localStorage.getItem(key) || initialValue);
    return value;
  });

  const setItem = (newValue) => {
    setValue(newValue);
    window.localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [item, setItem];
};

const CookieConsent = withHooks(() => {
  const [cookieConsent, setCookieConsent] = useLocalStorage(
    "cookieConsent",
    false
  );
  return (
    !cookieConsent && (
      <div className={styles.footer_bottom}>
        <div className={styles.footer_bottom_wrap}>
          <div className={styles.footer_bottom_wrap_text}>
            Confirm you agree to our{" "}
            <a href="/terms-and-conditions">terms and conditions</a>,{" "}
            <a href="/privacy-notice">privacy notice</a> and{" "}
            <a href="/cookie-statement">cookie statement</a>. Continuing to
            browse implies your acceptance.
          </div>
          <button
            className={styles.footer_bottom_wrap_button}
            onClick={(e) => {
              setCookieConsent(true);
            }}
          >
            I accept
          </button>
        </div>
      </div>
    )
  );
});

export default class Footer extends React.Component {
  render() {
    return (
      <div className={styles.footer} ref={this.props.bottomRef}>
        <div className={styles.title}>Hey Gorgeous, let’s get started!</div>
        <div className={styles.form}>
          <StepFirst />
        </div>

        <img src={logo} className={styles.logo} alt="error" />

        <p className={styles.text_information}>
          Copyright&copy; 2021 Schmodel Limited. All rights reserved.
        </p>
        <div className={styles.contact_us_button_container}>
          <p>
            Need help?{" "}
            <Link to="/contact-us" className={styles.need_help}>
              Contact us
            </Link>
          </p>
        </div>
        <CookieConsent />
      </div>
    );
  }
}
