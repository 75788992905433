import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { Field } from 'redux-form';
import withHooks, { useEffect } from 'react-with-hooks';
import { getCountryCallingCode } from 'libphonenumber-js';
import PhoneNumberInput from 'react-phone-number-input';

import Modal from '../Modal';
import StepSecond from '../StepSecond';
import WithBrand from '../WithBrand';
import { phoneNumber, email } from 'src/utils/validate';
import { ReactComponent as Warning } from 'src/assets/icons/warning.svg';

import styles from '../styles.module.css';
import stylesButton from '../../utils/css/button.module.css';

const Input = withHooks(({ input, ...props }) => {
  useEffect(() => {
    props.handleErrors({ name: input.name, error: props.meta.error });
  }, [props.meta.error]);

  return (
    <React.Fragment>
      <input
        className={`${styles.input} ${props.errors &&
          props.errors.find((err) => err.name === input.name) &&
          styles.error_input}`}
        {...input}
        required={true}
      />
      <label htmlFor={input.name}>{props.placeholder}</label>
    </React.Fragment>
  );
});

const InstagramInput = withHooks(({ input, ...props }) => {
  useEffect(() => {
    props.handleErrors({
      name: input.name,
      error: props.meta.error,
    });
  }, [props.meta.error]);
  return (
    <React.Fragment>
      <input
        className={`${styles.input} ${styles.input_group_in_input} ${
          styles.input_icons
        }`}
        {...props}
        {...input}
        required={true}
      />
      <label htmlFor={input.name} style={{ left: '45px', margin: '0 11px' }}>
        Instagram username
      </label>
    </React.Fragment>
  );
});

const PhoneNumberField = withHooks(({ input, ...props }) => {
  useEffect(() => {
    if (props.isEmpty) {
      props.meta.error = undefined;
    }
    props.handleErrors({
      name: input.name,
      error: props.meta.error,
    });
  }, [props.meta.error, props.isEmpty]);

  return (
    <React.Fragment>
      <PhoneNumberInput {...input} {...props} />
      {props.isRequired && (
        <label
          className={styles.require_phone_number}
          style={{ marginRight: '120px' }}
        >
          *
        </label>
      )}
    </React.Fragment>
  );
});

const CountryCode = ({ country }) => (
  <span style={{ color: 'white' }}>+{getCountryCallingCode(country)}</span>
);

class StepFirst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenStepSecond: false,
      isOpenSuccess: false,
      showPhoneValidationCheck: false,
      checkedResources: [],
      errors: [],
      isEmpty: false,
      isError: false,
      isRequired: true,
    };
  }

  handleCheckedResources = (resource) => {
    const checkedResources = [...this.state.checkedResources];
    if (checkedResources.includes(resource)) {
      const index = checkedResources.indexOf(resource);
      checkedResources.splice(index, 1);
    } else {
      checkedResources.push(resource);
    }
    this.setState({
      checkedResources: checkedResources,
    });
  };

  handleErrors = (error) => {
    const errors = [...this.state.errors];
    if (errors.length > 0) {
      const isExist = errors.some((err) => err.name === error.name);
      if (isExist) {
        const index = errors.findIndex((err) => err.name === error.name);
        if (!error.error) {
          errors.splice(index, 1);
        } else {
          errors[index].error = error.error;
        }
      } else {
        if (!error.error) {
          return;
        }
        errors.push(error);
      }
    } else if (error.error && errors.length === 0) {
      errors.push(error);
    }
    this.setState({
      errors: errors,
    });
  };

  checkRequired = (values) => {
    let required = 'All fields are required, darling!';
    if (!values.contactPhone) {
      this.handleErrors({ name: 'contactPhone.number', error: required });
    }
  };

  async onSubmit(values, dispatch, props, self) {
    values.resources = this.state.checkedResources;
    values = Object.assign({}, values);
    if (values.contactPhone && values.contactPhone.number) {
      if (values.resources.length > 0 && this.state.isOpenStepSecond) {
        await props.createBrand({
          variables: { input: values },
        });
        self.setState({
          isOpenSuccess: true,
        });
      } else {
        self.setState({
          isOpenStepSecond: true,
        });
      }
    } else {
      this.checkRequired(values);
    }
  }

  handleChange = (value) => {
    if (!value) {
      this.setState({ isEmpty: true });
      this.setState({ isRequired: true });
    } else {
      this.setState({ isEmpty: false });
      this.setState({ isRequired: false });
    }
  };

  render() {
    return (
      <Modal
        openModal={this.props.openBrandSignup}
        isOpenModal={this.props.isOpenBrandSignup}
      >
        <WithBrand
          onSubmit={(values, dispatch, props) =>
            this.onSubmit(values, dispatch, props, this)
          }
          handleOpenStepSecond={() =>
            this.setState({ isOpenStepSecond: false })
          }
        >
          {({ form: { handleSubmit, handleOpenStepSecond } }) => (
            <React.Fragment>
              {this.state.isOpenStepSecond ? (
                <StepSecond
                  openStepSecond={handleOpenStepSecond}
                  isSuccess={this.state.isOpenSuccess}
                  openModal={this.props.openBrandSignup}
                  handleSubmit={handleSubmit}
                  handleCheckedResources={this.handleCheckedResources}
                  checkedResources={this.state.checkedResources}
                  isError={this.state.isError}
                  setIsError={() => this.setState({ isError: true })}
                />
              ) : (
                <div className={styles.step_first}>
                  <p className={styles.title}>Enter your brand details below</p>
                  <form
                    className={styles.form_required}
                    onSubmit={handleSubmit}
                  >
                    <Field
                      component={Input}
                      name="website"
                      placeholder="Brand website"
                      handleErrors={this.handleErrors}
                      errors={this.state.errors}
                    />
                    <div
                      className={`${styles.input_group} ${this.state.errors &&
                        this.state.errors.find(
                          (err) => err.name === 'instagramUsername'
                        ) &&
                        styles.error_input}`}
                    >
                      <FaInstagram className="icon" size={25} />
                      <Field
                        component={InstagramInput}
                        name="instagramUsername"
                        handleErrors={this.handleErrors}
                      />
                    </div>
                    <Field
                      component={Input}
                      name="contactName"
                      placeholder="Contact name"
                      handleErrors={this.handleErrors}
                      errors={this.state.errors}
                    />
                    <div className={styles.wrap_phone_number}>
                      <Field
                        component={PhoneNumberField}
                        name="contactPhone.number"
                        placeholder="Contact Phone"
                        country={'GB'}
                        validate={[phoneNumber]}
                        limitMaxLength={true}
                        flagComponent={({ country }) => (
                          <CountryCode country={country} />
                        )}
                        displayInitialValueAsLocalNumber={true}
                        type="tel"
                        onChange={(value) => this.handleChange(value)}
                        className={`${
                          styles.combobox_phone_number
                        } ${this.state.errors.find(
                          (err) => err.name === 'contactPhone.number'
                        ) && styles.error_input_phone}`}
                        handleErrors={this.handleErrors}
                        errors={this.state.errors}
                        isRequired={this.state.isRequired}
                        isEmpty={this.state.isEmpty}
                      />
                    </div>
                    <Field
                      component={Input}
                      name="contactEmail"
                      placeholder="Contact Email"
                      validate={[email]}
                      type="email"
                      handleErrors={this.handleErrors}
                      errors={this.state.errors}
                    />
                    <div
                      className={`${styles.error_form} ${styles.absolute_err}`}
                    >
                      {this.state.errors && this.state.errors.length > 0 && (
                        <>
                          <span className={styles.warning_icon}>
                            <Warning />
                          </span>
                          <p>{this.state.errors[0].error}</p>
                        </>
                      )}
                    </div>
                    <button
                      className={`${stylesButton.button} ${
                        stylesButton.button_submit
                      } ${styles.button_submit}`}
                    >
                      Next
                    </button>
                  </form>
                </div>
              )}
            </React.Fragment>
          )}
        </WithBrand>
      </Modal>
    );
  }
}

export default StepFirst;
