import React from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { adopt } from "react-adopt";
import { reduxForm } from "redux-form";

import { destroy } from "redux-form";

import StateMachine from "src/components/utils/StateMachine";
import cloneDeep from "lodash/cloneDeep";

import * as FullStory from '@fullstory/browser';

import {
  UPDATE_USER_GENERAL_INFORMATION,
  UPDATE_USER_SKILLS,
  UPDATE_USER_LOCATIONS,
  UPDATE_USER_PHOTOS,
  SEND_VERIFY_CODE,
  CHECK_VERIFY_CODE,
  GET_CURRENT_USER,
  SEND_VERIFY_EMAIL,
  EXCHANGE_TOKEN,
  CHECK_VERIFY_EMAIL_CODE,
} from "./queries";

const FORM_NAME = "register";

const WithClient = ({ render }) => {
  const client = useApolloClient();
  return render(client);
};
const Form = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
})((props) => <React.Fragment>{props.render(props)}</React.Fragment>);

let WithUserForm = adopt({
  machine: <StateMachine.Consumer />,
  client: ({ render }) => <WithClient render={render} />,
  userQuery: ({ render }) => <UserQuery render={render} />,
  done: ({ render, destroy, ...props }) =>
    render(() => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("instagram_access_token");
      window.localStorage.removeItem("user_name");
      destroy(FORM_NAME);
      (async function() { await props.client.clearStore()})();
    }),
  updateUserGeneralInformation: ({ render }) => {
    const [updateUserGeneralInformationFunction, { loading, error, data }] = useMutation(UPDATE_USER_GENERAL_INFORMATION);
    return render(updateUserGeneralInformationFunction, { loading, error, data });
  },
  updateUserSkills: ({ render }) => {
    const [updateUserSkillsFunction, { loading, error, data }] = useMutation(UPDATE_USER_SKILLS);
    return render(updateUserSkillsFunction, { loading, error, data });
  },
  updateUserLocations: ({ render }) => {
    const [updateUserLocationsFunction, { loading, error, data }] = useMutation(UPDATE_USER_LOCATIONS);
    return render(updateUserLocationsFunction, { loading, error, data });
  },
  updateUserPhotos: ({ render }) => {
    const [updateUserPhotosFunction, { loading, error, data }] = useMutation(UPDATE_USER_PHOTOS);
    return render(updateUserPhotosFunction, { loading, error, data });
  },
  sendVerifyEmail: ({ render }) => {
    const [sendVerifyEmailFunction, { loading, error, data }] = useMutation(SEND_VERIFY_EMAIL);
    return render(sendVerifyEmailFunction, { loading, error, data });
  },
  checkEmailVerifyCode: ({ render }) => {
    const [checkEmailVerifyCodeFunction, { loading, error, data }] = useMutation(CHECK_VERIFY_EMAIL_CODE);
    return render(checkEmailVerifyCodeFunction, { loading, error, data });
  },
  sendVerifyCode: ({ render }) => {
    const [sendVerifyCodeFunction, { loading, error, data }] = useMutation(SEND_VERIFY_CODE);
    return render(sendVerifyCodeFunction, { loading, error, data });
  },
  checkVerifyCode: ({ render }) => {
    const [checkVerifyCodeFunction, { loading, error, data }] = useMutation(CHECK_VERIFY_CODE);
    return render(checkVerifyCodeFunction, { loading, error, data });
  },
  exchangeToken: ({ render }) => {
    const [exchangeTokenFunction, { loading, error, data }] = useMutation(EXCHANGE_TOKEN);
    return render(exchangeTokenFunction, { loading, error, data });
  },
  form: ({ userRequired = true, ...props }) => {
    const {
      userQuery: { data, loading, error },
    } = props;

    const currentUser = data && data.currentUser;

    return userRequired &&
      !loading &&
      (!window.localStorage.getItem("token") || !currentUser || error) ? (
      <Redirect to="/register" />
    ) : (
      <Form
        initialValues={window.localStorage.getItem("token") && currentUser}
        {...props}
      />
    );
  },
});

WithUserForm = connect(
  void 0,
  { destroy },
)(WithUserForm);

WithUserForm.format = (obj) => {
  //We first create a deep clone of object
  var cloneObj = cloneDeep(obj);
  return WithUserForm.formatObj(cloneObj);
};

WithUserForm.formatUserGeneralInformationInput = (input) => {
  const phoneInput = { number: input.phone.number};
  const userInput = {
    _id: input._id,
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    phone: phoneInput,
    referrer: input.referrer,
    instagramHandle: input.instagramHandle
  }
  return userInput;
};

WithUserForm.formatUserSkillsInput = (input) => {
  const userInput = {
    _id: input._id,
    skills: input.skills
  }
  return userInput;
};

WithUserForm.formatUserLocationsInput = (input) => {
    const homeCity = { geonamesId: input.homeCity.geonamesId};
    const userInput = {
      _id: input._id,
      homeCity: homeCity,
      locations: input.locations || []
    }
    return userInput;
};

WithUserForm.formatUserPhotosInput = (input) => {
  const userInput = {
    _id: input._id,
    instagramHandle: input.instagramHandle,
    images: input.images
  }
  return userInput;
};

WithUserForm.formatObj = (obj) => {
  // Here we transform a User object into a UserInput object which has less fields.
  for (var property in obj) {
    if (typeof obj[property] === "object" && obj[property] !== null) {
      delete obj.property;
      let newJsonData = WithUserForm.formatObj(obj[property]);
      obj[property] = newJsonData;
    } else {
      if (property === "__typename") {
        delete obj[property];
      }

      ["accepted_tos", "code", "instagramToken", "name"].forEach((field) => {
        delete obj[field];
      });
    }
  }
  return obj;
}

export default WithUserForm;

const UserQuery = ({ render }) => {
  const { data, loading, error } = useQuery(GET_CURRENT_USER, {
    onCompleted: (data) => {
      if (data.currentUser) {
        FullStory.identify(data.id, data.currentUser);
        // exchangeToken query is supposed to update the cached userQuery's user and
        // remove the images if the username associated to the Instagram access token is
        // not the same as the Instagram handle saved in the database.
        // But that does not work (we tried adding a `update=` to the exchangeToken mutation
        // and call cache.writeQuery()), and the following piece of code is a workaround.
        // Potential react-apollo bug: https://github.com/apollographql/react-apollo/issues/3288
        if (
            localStorage.getItem("user_name") &&
            data.currentUser.instagramHandle &&
            localStorage.getItem("user_name") !==
            data.currentUser.instagramHandle
        ) {
          data.currentUser.images = null;
        }
      }
    }
  });

  const queryResult = {
    data: data ? { currentUser: data.currentUser } : {},
    loading,
    error,
  };

  return render(queryResult);
};