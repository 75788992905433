import React from "react";
import StepFirst from "./StepFirst";

export default class BrandSignup extends React.Component {
  render() {
    return (
      <StepFirst
        openBrandSignup={this.props.openBrandSignup}
        isOpenBrandSignup={this.props.isOpenBrandSignup}
      />
    );
  }
}
