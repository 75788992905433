import React from "react";
import item1 from "../../../../assets/images/bag_img.png";
import item2 from "../../../../assets/images/group_of_models.png";
import item3 from "../../../../assets/images/img_3_1.png";
import schModelSlogan from "../../../../assets/images/fixed_beauty_logo.png";
import backgroundImage from "../../../../assets/images/hello_brands_bg.png";

import styles from "./styles.module.css";

const Item = ({ title, desc, className }) => {
  return (
    <div className={styles.item}>
      <h3 className={styles.title_item}>{title}</h3>
      <p className={`${className} ${styles.desc_item}`}> {desc}</p>
    </div>
  );
};

export default class HelloBrands extends React.Component {
  render() {
    return (
      <div className={styles.hello_brands}>
        <div className={`${styles.wrap_bg} ${styles.for_desktop}`}>
          <img src={backgroundImage} />
        </div>
        <div className={styles.hello_brands_container}>
          <div className={styles.hello_brands_container_block_left}>
            <h1 className={styles.hello_brands_container_block_left_title}>
              Hello Brands
            </h1>
            <p className={styles.hello_brands_container_block_left_sub_title}>
              This is who we are
            </p>
            <Item
              className={styles.first_desc_item}
              title="We let you choose"
              desc="An open platform to find talent
            No bookers to get in your way"
            />
            <Item
              className={styles.second_desc_item}
              title="Our fees are transparent"
              desc={
                "No hidden fees, only consistent rates You pay for what you get"
              }
            />
            <Item
              title="Studio and retail space"
              desc="Shoot in our studio free of charge
            Promote your brand in our space"
            />
            <img
              src={item1}
              className={`${styles.img_bag} ${styles.for_desktop}`}
            />
          </div>
          <div
            className={`${styles.hello_brands_container_block_center} ${
              styles.for_desktop
            }`}
          >
            <img
              className={styles.text_top}
              src={schModelSlogan}
              alt="schmodel slogan"
            />
            <img src={item3} />
            <p className={styles.text_bottom}>
              OUR PLATFORM ALLOWS THE CLIENT TO CHOOSE FOR THEMSELVES
            </p>
          </div>
          <div
            className={`${styles.hello_brands_container_block_right} ${
              styles.for_desktop
            }`}
          >
            <p className={styles.text_block_right}>
              We believe
              <br /> human bias is <br />
              unfair and costly
            </p>
            <div className={styles.right_bottom}>
              <img src={item2} />
              <div className={styles.text_block_right_bottom}>
                WE BELIEVE THAT
                <br /> THE CHOICE OF TALENT SHOULD BE
                <br />
                IN THE HANDS OF THE CLIENT
              </div>
            </div>
          </div>
        </div>
        <div className={styles.for_mobile}>
          <div className={`${styles.wrap_bg}`}>
            <div className={`${styles.wrap_inner}`}>
              <img
                className={styles.text_top}
                src={schModelSlogan}
                alt="schmodel slogan"
              />
              <p className={styles.text_block_right}>
                We believe that human bias is <br /> unfair and costly
              </p>
            </div>
          </div>
          <img src={item1} />
          <div className={styles.block_asob}>
            <img src={item3} />
          </div>
          <p className={styles.text_bottom}>
            OUR PLATFORM ALLOWS THE CLIENT <br />
            TO CHOOSE FOR THEMSELVES
          </p>
          <div className={styles.text_block_right_bottom}>
            <img src={item2} />
            <div>
              WE BELIEVE THAT THE CHOICE <br /> OF TALENT SHOULD BE IN THE
              <br />
              HANDS OF THE CLIENT
            </div>
          </div>
        </div>
      </div>
    );
  }
}
