import React from "react";

import { reduxForm, SubmissionError, formValues, Field, change } from "redux-form";
import fetchJsonp from "fetch-jsonp";
import qs from "query-string";
import { useMutation } from '@apollo/client';
import gql from "graphql-tag";

import removeHash from "src/utils/location";
import WithUserForm from "../../Register/WithUserForm";
import Step from "../Step";

import isChecked from "./insta-checkmark.svg";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";
import withHooks, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react-with-hooks";
import { withRouter } from "react-router-dom";
import blankImage from "../../../assets/images/blank.gif";

const maxNumberOfImages = 9;
const minNumberOfImages = 9;

const SelectPhotos = withHooks((props) => {
  const imagesRef = useRef();

  useEffect(() => {
    props.getImages(props.meta && props.meta.initial);
  }, [props.meta.initial]);

  useEffect(() => {
    let timeOutClassNextPage;
    if (props.isNextPage) {
      timeOutClassNextPage = props.handleNextPage();
    }

    return () => {
      if (timeOutClassNextPage) {
        clearTimeout(timeOutClassNextPage);
      }
    };
  }, [props.isNextPage]);

  useEffect(() => {
    imagesRef.current.addEventListener("scroll", function() {
      if (
        imagesRef.current.scrollHeight -
          Math.abs(imagesRef.current.scrollTop) <=
        imagesRef.current.clientHeight + 1
      ) {
        props.setIsNextPage(true);
      }
    });
  }, []);

  return (
    <Step backgroundImage={""} classNames={styles.step_five}>
      <p className={`${styles.title} ${styles.title_stepfive}`}>
        Nice work beautiful! Almost there.
      </p>
      <p className={`${styles.sub_title} ${styles.sub_title_five}`}>
        Your account @
        {localStorage.getItem("user_name")
          ? localStorage.getItem("user_name")
          : "Unknown"}{" "}
        <br />
        is connected. <br /> <br />
      </p>
      {/* Display a red warning message if the Instagram account has fewer than 9 photos */}
      {props.imageData?.images?.length < 9 && (
        <p className={`${styles.error_message}`}>
          Your Instagram account has less than 9 photos.  <br/>
          You need at least 9 photos to continue.
        </p>
      )}
      {props.imageData?.images?.length >= 9 && (
          <p>
            Please select 9 photos from your Instagram <br/>
            account that best represent your work.
          </p>
      )}
      <div className={styles.step_five_action_container}>
        <button
          className={`${stylesButton.button} ${stylesButton.button_back}`}
          onClick={props.onBack}
        >
          Back
        </button>
        {props.imageData?.images?.length >= 9 && (
          <>
            <div className={styles.image_count}>
              {props.imagesSelected && props.imagesSelected.length}/9 selected
            </div>
            <button
              disabled={props.imagesSelected.length < 9 ? true : false}
              className={`${stylesButton.button} ${stylesButton.button_submit} ${
                  styles.button_submit
              }`}
              type="submit"
            >
              Confirm
            </button>
          </>
        )}
      </div>
      <div className={props.classNextPage}>
        <div className={`${styles.list_image}`} ref={imagesRef}>
          {props.imageData?.images?.length >= 9 && (
            props.imageData.images.map((image) => (
              <div
                className={styles.image}
                key={image.id}
                onClick={() => props.selectImage(image)}
              >
                <img src={blankImage} style={{ backgroundImage: `url(${image.media_url})`}} alt="" />
                {props.imagesSelected &&
                  props.imagesSelected.map(
                    (imageSelected) =>
                      image.id === imageSelected.id && (
                        <img
                          key={image.id}
                          className={styles.selected_image}
                          src={isChecked}
                          alt="error"
                        />
                      ),
                  )}
              </div>
            ))
          )}
          {/* Display the pictures even if there are fewer than 9 */}
          {/* The user is not allowed to select them */}
          {props.imageData?.images?.length < 9 && (
            props.imageData.images.map((image) => (
              <div
                className={styles.image}
                style={{
                  cursor: "default"
                }}
                key={image.id}
              >
                <img src={blankImage} style={{backgroundImage: `url(${image.media_url})`}} alt=""/>
              </div>
            ))
          )}
        </div>
        {(!props.imageData?.images || props.imageData?.images?.length < 9) && <p className={`${styles.p} ${styles.notEnoughImages}`}>
          Instagram is a model’s, actor’s and performer’s lookbook. Please
          have at least {minNumberOfImages} photos in your Instagram account
          showing your best work and then come back and register with us.
        </p>}
      </div>
    </Step>
  );
});

let Form = withHooks(
  ({
    form: { handleSubmit, invalid, pristine, submitting },
    machine,
    selectImage,
    imagesSelected,
    getImages,
    handleNextPage,
    classNextPage,
    setIsNextPage,
    isNextPage,
    imageData,
  }) => {
    const [data, setData] = useState({});

    useLayoutEffect(() => {
      if (imageData) {
        setData(imageData);
      }
    }, [imageData]);

    return (
      <form onSubmit={handleSubmit} className={styles.SelectPhotos}>
        <React.Fragment>
          <Field
            name="images"
            component={SelectPhotos}
            maxNumberOfImages={maxNumberOfImages}
            selectImage={selectImage}
            imagesSelected={imagesSelected}
            getImages={getImages}
            handleNextPage={handleNextPage}
            classNextPage={classNextPage}
            setIsNextPage={setIsNextPage}
            isNextPage={isNextPage}
            onBack={() => machine.service.send({ type: "BACK" })}
            imageData={data}
          />
        </React.Fragment>
      </form>
    );
  },
);

Form = formValues("images")(Form);

class StepFive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesSelected: [],
      isNextPage: false,
      classNextPage: "",
      imageData: {},
      instagramHandleFromMeMediaEndpoint: null
    };
  }

  componentWillMount() {
    if(window.localStorage.getItem("instagram_access_token"))
      this.getImages();
  }

  getImagesFromLocalStorage = (images) => {
    if (images && images.length > 0) {
      this.setState({
        imagesSelected: images,
      });
    }
  };

  handleSelectImage = (image) => {
    const images = [...this.state.imagesSelected];
    const index = this.state.imagesSelected.findIndex(
      (obj) => obj.id === image.id,
    );

    if (images.length <= 9 && index > -1) {
      images.splice(index, 1);
    } else if (images.length < 9) {
      images.push(image);
    }

    this.setState({
      imagesSelected: images,
    });
  };

  handleImages = (body) => {
    if (body.hasOwnProperty("data")) {
      let images = [];
      const media = body.data.filter(
        (media) =>
          media.media_type === "IMAGE" || media.media_type === "CAROUSEL_ALBUM",
      );
      let instagramHandleFromMeMediaEndpoint = null;
      if (media.length > 0) {
        media.forEach((post) => {
          if (post.children) {
            const postChildren = post.children.data.filter(
              (child) =>
                child.media_type === "IMAGE" ||
                child.media_type === "CAROUSEL_ALBUM",
            );
            if (postChildren.length > 0) {
              // first child contains working permalink that redirects to root post permalink
              let carouselRootPermalink = null;
              carouselRootPermalink = postChildren[0].permalink;
              postChildren.forEach((child, index) => {
                child.carouselRootPermalink = carouselRootPermalink;
                child.carouselChildIndex = index+1;
              });
            }
            images = images.concat(postChildren);
          } else {
            images.push(post);
          }
        });
        instagramHandleFromMeMediaEndpoint = media[0].username;
      }

      const imageData = {
        images: (
          (this.state.imageData && this.state.imageData.images) ||
          []
        ).concat(images),
        paging: body.paging,
      };

      this.setState({ imageData });
      this.setState({ instagramHandleFromMeMediaEndpoint });
      for (const singleMedia of media) {
        if (singleMedia.media_type === "CAROUSEL_ALBUM") {
            for (const carouselMedia of singleMedia.children.data) {
                this.retrieveThumbnail(carouselMedia);
            }
        } else {
            this.retrieveThumbnail(singleMedia);
        }
      }
    }
  };

  handleIsNext = (isNext) => {
    this.setState({ isNextPage: isNext });
  };

  handleNextPage = async () => {
    let timeOutClassNextPage;
    if (
      this.state.imageData &&
      this.state.imageData.paging &&
      this.state.imageData.paging.next
    ) {
      const body = await fetchJsonp(this.state.imageData.paging.next).then(
        (res) => res.json(),
      );

      this.setState({
        classNextPage: `${styles.list_image_active_next_page}`,
      });

      timeOutClassNextPage = setTimeout(() => {
        this.setState({ classNextPage: "" });
      }, 2000);

      this.handleImages(body);
    }

    this.setState({ isNextPage: false });

    return timeOutClassNextPage;
  };

  getImages = async () => {
    const accessToken = localStorage.getItem("instagram_access_token");
    removeHash();

    const queryString = qs.stringify({
      access_token: accessToken,
    });

    const body = await fetchJsonp(
      "https://graph.instagram.com/v17.0/me/media/?fields=id,media_type,media_url,permalink,children{media_type, media_url,permalink},username&" +
        queryString,
    ).then((res) => res.json())
    .catch (error => console.log(error));

    this.handleImages(body);
  };

  retrieveThumbnail = async (carouselMedia) => {
    carouselMedia.thumbnail_url = await this.props
      .instagramThumbnailUrl({
        variables: {
          instagramMediaPermalink: carouselMedia.permalink,
          large: true,
        },
      })
      .then((res) => res.data.instagramThumbnailUrl)
      .catch(error => console.error('Error:', error));
  };

  onSubmit = async (values, _, props) => {
    let updatedImages = [...this.state.imagesSelected]
    let updatedInstagramHandle = this.state.instagramHandleFromMeMediaEndpoint
    if (!updatedImages || updatedImages.length < minNumberOfImages) {
      throw new SubmissionError({
        images: `Please select at least ${minNumberOfImages} photos.`,
      });
    }
    props.dispatch(change('register', 'images', updatedImages));
    props.dispatch(change('register', 'instagramHandle', updatedInstagramHandle));
    const formattedValues = WithUserForm.formatUserPhotosInput({...values, images: updatedImages, instagramHandle: updatedInstagramHandle});
    await props.updateUserPhotos({ variables: { input: formattedValues } });

    return props.machine.service.send({
      type: "NEXT",
    });
  };

  render() {
    return (
      <WithUserForm onSubmit={this.onSubmit}>
        {(props) => (
          <>
            <Form
              {...props}
              handleNextPage={this.handleNextPage}
              classNextPage={this.state.classNextPage}
              isNextPage={this.state.isNextPage}
              setIsNextPage={this.handleIsNext}
              selectImage={this.handleSelectImage}
              imagesSelected={this.state.imagesSelected}
              getImages={this.getImagesFromLocalStorage}
              imageData={this.state.imageData}
            />
          </>
        )}
      </WithUserForm>
    );
  }
}

const INSTAGRAM_THUMBNAIL_URL = gql`
  mutation instagramThumbnailUrl(
    $instagramMediaPermalink: String
    $large: Boolean
  ) {
    instagramThumbnailUrl(instagramMediaPermalink: $instagramMediaPermalink, large: $large)
  }
`;
const withInstagramThumbnailUrl = (Component) => (props) => {
  const [instagramThumbnailUrl, { data, loading, error }] = useMutation(INSTAGRAM_THUMBNAIL_URL);
  return <Component {...props} instagramThumbnailUrl={instagramThumbnailUrl} />;
};

StepFive = withInstagramThumbnailUrl(StepFive);

StepFive = reduxForm({
  form: "register",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(StepFive);

StepFive = withRouter(StepFive);

export default StepFive;
