import React from "react";
import styles from "./styles.module.css";

export const Checkbox = (props) => {
  const { isChecked, disabled } = props;
  return (
    <label className={`${styles.wrap_checkbox} ${props.className}`}>
      <input type="checkbox" checked={isChecked} disabled={disabled} />
      <span className={styles.check_mark} />
    </label>
  );
};
