import React from "react";
import styles from "./styles.module.css";

export const Checkbox = (props) => {
  const { isChecked } = props;
  return (
    <label>
      <div className={`${styles.wrap_checkbox} ${isChecked && styles.checked}`}>
        <span className={styles.label}>{props.label}</span>
        <span className={styles.check_mark} />
      </div>
    </label>
  );
};
