import React, { useEffect, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import PhoneNumberInput from "react-phone-number-input";
import { getCountryCallingCode } from "libphonenumber-js";
import { Field } from "redux-form";
import { Link, withRouter } from "react-router-dom";

import styles from "../styles.module.css";

import WithUserForm from "../../Register/WithUserForm";
import {
  required,
  email,
  phoneNumber,
  nonSpecialChar,
  nonStartWithSpace,
} from "src/utils/validate";
import { ReactComponent as Warning } from "src/assets/icons/warning.svg";

const ERROR_EMPTY = "All fields are required, darling!";
const instagramPlaceholderSelf = "Your IG account";
const instagramPlaceholderReferral = "Who referred you? (enter their IG)";

const Input = ({ input, placeholder, inputClass, placeholderClass, redStarClass, ...props }) => {
  useEffect(() => {
    if (props.meta.touched) {
      props.handleErrors({
        name: input.name,
        error: props.meta.error,
      });
    }
  }, [props.meta.error, props.meta.touched, input.value]);

  return (
      <React.Fragment>
        {placeholder !== instagramPlaceholderSelf &&
        placeholder !== instagramPlaceholderReferral ? (
            <input
                className={`${styles.input} ${props.meta.error &&
                props.meta.touched &&
                styles.error_input} ${inputClass}`}
                {...input}
                id={input.name}
            />
        ) : null}

        {placeholder === instagramPlaceholderSelf ||
        placeholder === instagramPlaceholderReferral ? (
            <input
                className={`${styles.input} ${styles.input_group_in_input} ${
                    styles.input_icons
                } ${props.meta.error && props.meta.touched && styles.error_input}`}
                {...props}
                {...input}
                id={input.name}
            />
        ) : null}
        {!input.value && (
            <label
                className={`${styles.placeholder_label} ${placeholderClass}`}
                htmlFor={input.name}
            >
              {placeholder}
              <span className={redStarClass}>
            &#42;
          </span>
            </label>
        )}
      </React.Fragment>
  );
};

const PhoneNumberField = ({ input, ...props }) => {
  const [isClear, setIsClear] = useState(false);

  useEffect(() => {
    if (props.isEmpty) {
      setIsClear(true);
      props.handleErrors({
        name: input.name,
        error: ERROR_EMPTY,
      });
      return;
    }

    if (props.meta.touched) {
      props.handleErrors({
        name: input.name,
        error: props.meta.error,
      });
    }
  }, [props.meta.error, props.isEmpty, props.meta.touched]);

  return (
    <React.Fragment>
      <PhoneNumberInput
        {...input}
        {...props}
        className={`${styles.combobox_phone_number} ${(props.meta.error ||
          props.isEmpty) &&
          props.meta.touched &&
          styles.error_input_phone}`}
      />
      {props.data && props.data.phone && props.data.phone.number && !isClear
        ? null
        : props.isRequired && (
            <label
              className={styles.require_phone_number}
              style={{ right: "60%" }}
            >
              *
            </label>
          )}
    </React.Fragment>
  );
};

const AcceptedTosInput = ({ input, ...props }) => {
  useEffect(() => {
    if (props.meta.touched) {
      props.handleErrors({
        name: input.name,
        error: props.meta.error,
      });
    }
  }, [props.meta.error, props.meta.touched]);

  return (
      <label className={styles.wrap_checkbox}>
        <input
            type="checkbox"
            className={styles.wrap_checkbox_input}
            {...input}
            {...props}
            checked={input.value}
        />
        <span className={styles.check_mark} />
      </label>
  );
};

const CountryCode = ({ country }) => (
  <span style={{ color: "white" }}>+{getCountryCallingCode(country)}</span>
);

class StepFirstClass {
  errors = [];
  setErrors(error) {
    this.errors = this.errors.concat(error);
  }
}

const stepFirstClass = new StepFirstClass();

class StepFirst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isEmpty: false,
      isRequired: true,
      isFirstTime: true,
      indexChange: 0,
    };
  }

  handleChange = (value) => {
    if (!value) {
      this.setState({ isEmpty: true });
      this.setState({ isRequired: true });
    } else {
      this.setState({ isEmpty: false });
      this.setState({ isRequired: false });
    }
  };

  handleErrors = (error) => {
    let errors = [...this.state.errors];

    if (errors.length > 0) {
      const isExist = errors.some((err) => err.name === error.name);
      if (isExist) {
        const index = errors.findIndex((err) => err.name === error.name);
        if (!error.error) {
          errors.splice(index, 1);
          this.setState({ indexChange: 0 });
        } else {
          errors[index].error = error.error;
          this.setState({ indexChange: index });
        }
      } else {
        if (error.error) {
          errors.push(error);
          this.setState({ indexChange: errors.length - 1 });
        }
      }
    } else {
      if (error.error) {
        errors.push(error);
      }
    }

    if (this.state.isFirstTime && error.error) {
      stepFirstClass.setErrors(error);
      errors = stepFirstClass.errors;
      this.setState({ isFirstTime: false });
    }

    errors = [...new Map(errors.map((err) => [err["name"], err])).values()];

    this.setState({
      errors,
    });
  };

  isErrorAcceptedTos = () => {
    if (this.state.errors.find((err) => err.name === "accepted_tos")) {
      return true;
    }
    return false;
  };

  async onSubmit(values, dispatch, props, self) {
    values = Object.assign({}, values);
    try {
      if (this.state.isEmpty) {
        return;
      }
      const formattedValues = WithUserForm.formatUserGeneralInformationInput(values);
      const updateUserGeneralInformationResult = await props.updateUserGeneralInformation({
        variables: {input: formattedValues},
      });
      window.localStorage.setItem("token", updateUserGeneralInformationResult.data.updateUserGeneralInformation.auth.token)
      props.machine.service.send({
        type: "NEXT",
      });
      await props.userQuery.refetch();
    } catch (e) {
      if (e.graphQLErrors) {
        this.handleErrors({
          name: "submit",
          error: e.graphQLErrors[0].message,
        });
      }
    }
  }

  render() {
    return (
        <WithUserForm
            userRequired={false}
            onSubmit={(values, dispatch, props) =>
                this.onSubmit(values, dispatch, props, this)
            }
        >
          {({ form: { handleSubmit, anyTouched }, userQuery: { data } }) => (
              <form
                  onSubmit={handleSubmit}
                  className={`${styles.form_require} ${styles.step_first}`}
              >
                <Field
                    component={Input}
                    validate={[required, nonSpecialChar, nonStartWithSpace]}
                    name="firstName"
                    placeholder="First name"
                    handleErrors={this.handleErrors}
                    errors={this.state.errors}
                    placeholderClass={styles.firstNamePlaceholder}
                    inputClass={styles.firstNameInput}
                />

                <Field
                    name="lastName"
                    component={Input}
                    validate={[required, nonSpecialChar, nonStartWithSpace]}
                    placeholder="Last name"
                    handleErrors={this.handleErrors}
                    errors={this.state.errors}
                    placeholderClass={styles.lastNamePlaceholder}
                    inputClass={styles.lastNameInput}
                />

            <div className={styles.wrap_phone_number}>
              <Field
                name="phone.number"
                component={PhoneNumberField}
                validate={[required, phoneNumber]}
                placeholder="Phone"
                country={"GB"}
                limitMaxLength={true}
                flagComponent={({ country }) => (
                  <CountryCode country={country} />
                )}
                displayInitialValueAsLocalNumber={true}
                type="tel"
                onChange={(value) => this.handleChange(value)}
                handleErrors={this.handleErrors}
                errors={this.state.errors}
                isEmpty={this.state.isEmpty}
                isRequired={this.state.isRequired}
                data={data && data.currentUser}
                placeholderClass={styles.phonePlaceholder}
                inputClass={styles.phoneInput}
              />
            </div>

                <Field
                    name="email"
                    component={Input}
                    validate={[required, email]}
                    placeholder="Email"
                    type="email"
                    handleErrors={this.handleErrors}
                    errors={this.state.errors}
                    placeholderClass={styles.emailPlaceholder}
                    inputClass={styles.emailInput}
                />

                <div className={`${styles.instagram_input}`}>
              <span>
                <FaInstagram className="icon" size={25} />
              </span>
                  <span className={styles.input_prefix}>@</span>
                  <Field
                      name="instagramHandle"
                      component={Input}
                      validate={[required, nonStartWithSpace]}
                      placeholder={instagramPlaceholderSelf}
                      type="text"
                      handleErrors={this.handleErrors}
                      errors={this.state.errors}
                      placeholderClass={styles.instagramHandlePlaceholder}
                      inputClass={styles.instagramHandleInput}
                      redStarClass={styles.red_star_self}
                  />
                </div>

                <div className={`${styles.instagram_input} `}>
              <span>
                <FaInstagram className="icon" size={25} />
              </span>
                  <span className={styles.input_prefix}>@</span>

                  <Field
                      name="referrer"
                      component={Input}
                      placeholder={instagramPlaceholderReferral}
                      type="text"
                      handleErrors={this.handleErrors}
                      errors={this.state.errors}
                      placeholderClass={styles.referrerPlaceholder}
                      inputClass={styles.referrerInput}
                      redStarClass={styles.red_star_referrer}
                  />
                  <div className={styles.tool_tip}>
                    <button data-tip data-for="registerTip">
                      <AiOutlineQuestionCircle size={18} />
                    </button>
                    <ReactTooltip
                        id="registerTip"
                        border={true}
                        borderColor={"#979797"}
                        backgroundColor={"#000000"}
                        effect="solid"
                        multiline={true}
                        clickable={true}
                        className={styles.description_tooltip}
                    >
                      <p>
                        Enter the Instagram handle of your friend who told you about
                        us, and let them share your success! Don’t forget to tell
                        your friends to do the same for you…
                      </p>
                    </ReactTooltip>
                  </div>
                </div>

                <div
                    className={`${
                        styles.confirm_block_container
                    } ${this.isErrorAcceptedTos() && styles.error_input}`}
                >
                  <Field
                      name="accepted_tos"
                      normalize={(v) => !!v}
                      format={(v) => !!v}
                      component={AcceptedTosInput}
                      validate={[required]}
                      handleErrors={this.handleErrors}
                  />

                  <div className={styles.text}>
                    Confirm and click Next to agree to our{" "}
                    <Link to="/terms-and-conditions" className={styles.text_span}>
                      Terms & Conditions
                    </Link>
                    ,{" "}
                    <Link to="/privacy-notice" className={styles.text_span}>
                      Privacy Notice
                    </Link>{" "}
                    and{" "}
                    <Link to="/cookie-statement" className={styles.text_span}>
                      Cookie statement
                    </Link>
                    .
                  </div>
                </div>
                <div className={styles.error_form}>
                  {anyTouched && this.state.errors && this.state.errors.length > 0 && (
                      <>
                  <span className={styles.warning_icon}>
                    <Warning />
                  </span>
                        {this.state.errors.map(
                            (err, index) =>
                                this.state.indexChange === index && (
                                    <p key={err.name}>{`${err.error}`}</p>
                                ),
                        )}
                      </>
                  )}
                </div>
                <button className={`${styles.button}`}>Next</button>
              </form>
          )}
        </WithUserForm>
    );
  }
}

StepFirst = withRouter(StepFirst);

export default StepFirst;
