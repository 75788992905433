import "core-js/stable";
import "regenerator-runtime/runtime";
import "unfetch/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "src/data/store";

import client from "src/data/apolloClient";
import { ApolloProvider } from '@apollo/client';

import ReactGA from 'react-ga4';
import * as FullStory from '@fullstory/browser';

ReactGA.initialize('G-BMCW0VGK6F');
FullStory.init({ orgId: 'JACTR' });

history.listen(location => {
    ReactGA.send({hitType: "pageview", path: location.pathname + location.search});
});

const Root = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
