import register from "./register";
import admin from "./admin";

const stateChart = {
  initial: "register",
  context: {},
  states: {
    register,
    admin
  }
};

export default stateChart;
