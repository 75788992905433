import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token") || undefined;

  return {
    headers: {
      ...headers,
      Authorization: token
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink,
    new BatchHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI
    })
  ]),
  cache: new InMemoryCache()
});

export default client;
