import React from "react";
import { Field, change } from "redux-form";

import westronglypromoteBackground from "../../../assets/images/westronglypromote-1.png";
import westronglypromoteBackgroundMobile from "../../../assets/images/whereareyoubased_mobile.png";
import { ReactComponent as Warning } from "src/assets/icons/warning.svg";

import Step from "../Step";
import { Row } from "../../utils/InputGroup";
import { Checkbox } from "../../utils/Checkbox-v2";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";
import WithUserForm from "../../Register/WithUserForm";
import withHooks, { useEffect } from "react-with-hooks";

const skills = [
  "Fashion model",
  "Influencer",
  "Content creator",
  "Photographer / Videographer",
  "Hair stylist",
  "Makeup artist",
  "Creative director",
  "Production manager",
];

const SelectedSkills = withHooks((props) => {
  useEffect(() => {
    props.getSkills(props.input.value);
  }, [props.input.value]);

  return (
    <React.Fragment>
      {props.skills.map((skill) => (
        <Row handleChecked={() => props.handleCheckedSkills(skill)} key={skill}>
          <Checkbox
            label={skill}
            isChecked={props.checkedSkills.includes(skill)}
          />
        </Row>
      ))}
    </React.Fragment>
  );
});

export default class StepSecond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedSkills: [],
      isError: false,
    };
  }

  getSkills = (skills) => {
    let listSkill = [];
    if (skills && skills.length > 0) {
      listSkill = listSkill.concat(skills);
    }
    this.setState({
      checkedSkills: listSkill,
    });
  };

  handleCheckedSkills = (skill) => {
    const checkedSkills = [...this.state.checkedSkills];
    if (checkedSkills.includes(skill)) {
      const index = checkedSkills.indexOf(skill);
      checkedSkills.splice(index, 1);
    } else {
      checkedSkills.push(skill);
    }
    this.setState({
      checkedSkills: checkedSkills,
    });
  };

  async onSubmit(values, _, props) {
    let updatedSkills = [...this.state.checkedSkills]
    if (updatedSkills.length === 0) {
      this.setState({ isError: true });
    } else {
      props.dispatch(change('register', 'skills', updatedSkills));
      const formattedValues = WithUserForm.formatUserSkillsInput({...values, skills: updatedSkills});
      const response = await props.updateUserSkills({
        variables: { input: formattedValues },
      });
      return props.machine.service.send({
        type: "NEXT",
      });
    }
  }

  render() {
    return (
      <Step
        backgroundImage={
          window.innerWidth > 500
            ? westronglypromoteBackground
            : westronglypromoteBackgroundMobile
        }
        classNames={styles.step_second}
        className={styles.wrapper}
      >
        <p className={styles.title}>What do you do?</p>
        <p className={styles.sub_title}>Select one or more of the following:</p>
        <WithUserForm
          onSubmit={(values, _, props) => this.onSubmit(values, _, props, this)}
        >
          {({
            form: { handleSubmit, invalid, pristine, submitting },
            machine,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form_step_second}>
              <Field
                name="skills"
                component={SelectedSkills}
                handleCheckedSkills={this.handleCheckedSkills}
                skills={skills}
                checkedSkills={this.state.checkedSkills}
                getSkills={this.getSkills}
              />
              <div className={styles.error_form}>
                {this.state.checkedSkills.length === 0 && this.state.isError && (
                  <>
                    <span className={styles.warning_icon}>
                      <Warning />
                    </span>
                    <p>Please select your skill(s)!</p>
                  </>
                )}
              </div>
              <div className={`flex justify-between ${styles.wrapper_button}`}>
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_back
                  }`}
                  onClick={() => machine.service.send("BACK")}
                >
                  Back
                </button>
                <button
                  className={`${stylesButton.button} ${
                    stylesButton.button_submit
                  }`}
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </form>
          )}
        </WithUserForm>
      </Step>
    );
  }
}
