import React, { Component } from "react";
import vhCheck from "vh-check";

import "./index.css";

import { withRouter } from "react-router";

import TalentSignup from "../TalentSignup";
import stateChart from "./stateChart";
import StateMachine from "../utils/StateMachine";

import { Route } from "react-router-dom";
import asyncComponent from "src/utils/asyncComponent.js";
// import Test1 from "../TalentSignup/StepFirst/index";
// import Test2 from "../TalentSignup/StepSecond/index";
// import Test3 from "../TalentSignup/StepThird/index";
// import Test4 from "../TalentSignup/StepFourth/AccessInstagram";
// import Test5 from "../TalentSignup/StepFive/index";
// import Test6 from "../TalentSignup/StepConfirmation/index";
// import Test7 from "../TalentSignup/Success/index";
// import Modal from "../utils/ModalLegalAgreements/index";
// import Header from "../Register/Header/index";

// Sets css variable --vh-offset
vhCheck();

const Contact = asyncComponent(() =>
  import("src/components/Register/ContactUs")
);
const TermsConditions = asyncComponent(() =>
  import("src/components/LegalAgreements/TermsConditions")
);

const WhoAreWe = asyncComponent(() =>
  import("src/components/Register/WhoAreWe")
);

const ReferralCode = asyncComponent(() =>
  import("src/components/Register/ReferralCode")
);

const CookieStatement = asyncComponent(() =>
  import("src/components/LegalAgreements/CookieStatement")
);

const PrivacyNotice = asyncComponent(() =>
  import("src/components/LegalAgreements/PrivacyNotice")
);

const Rewards = asyncComponent(() => import("src/components/Register/Rewards"));
const Admin = asyncComponent(() => import("src/components/Admin"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  // showWhoAreWeIfMobile = () => {
  //   const { windowWidth } = this.state;
  //   const isMobile = windowWidth <= 1000;

  //   return window.location.pathname === "/" && isMobile ? (
  //     <Route exact path="/" component={WhoAreWe} />
  //   ) : null;
  // };

  render() {
    return (
      <StateMachine machine={stateChart}>
        {/* <StateMachine.State is="register.whoAreWe">
          {this.showWhoAreWeIfMobile()}
        </StateMachine.State> */}
        <TalentSignup />
        {/* <Route path="/" component={Home} /> */}
        <Route path="/admin" component={Admin} />
        {/* <Route path="/admin" component={Test3} /> */}
        <Route path="/terms-and-conditions" component={TermsConditions} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/cookie-statement" component={CookieStatement} />
        <Route path="/privacy-notice" component={PrivacyNotice} />
      </StateMachine>
    );
  }
}

App = withRouter(App);

export default App;
