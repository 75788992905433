import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import styles from "./index.module.css";
import debounce from "debounce-promise";
import qs from "query-string";
const fetchAutocomplete = debounce(
  (input) => {
    const queryString = qs.stringify({
      nameStart: input,
      minPopulation: 250000,
    });
    return fetch(
      `https://6i9d7zjpoa.execute-api.eu-west-1.amazonaws.com/production/?${queryString}`
    ).then(res => res.json());
  },
  1, // 1 ms.
  true
);

class PlacesAutocomplete extends Component {
  state = {
    value: "",
    selectedValue: null,
    places: [],
    latestFetchIndex: 0,
    firstTime: true
  };

  componentDidUpdate() {
    if(this.props.value?.geonamesId && this.state.firstTime){
      this.setState({ firstTime: false });
      this.props.handleHomeCityGeonamesId(this.props.value.geonamesId);
    }
  }
  render() {
    return (
      <Autocomplete
        inputProps={{
          id: "states-autocomplete",
          className: `${this.props.admin? styles.AdminInput: styles.Input} ${this.props.className}`,
          onBlur: (e) => {
            if (!this.state.selectedValue && this.props.meta.dirty && !this.props.value?.geonamesId) {
              this.props.onChange({
                  name: '',
                  geonamesId: ''
              });
            }
            this.props.onBlur();
          },
          placeholder: this.props.placeholder,
          onFocus: () => {
            fetchAutocomplete(this.state.value).then((items) => {
              this.setState({ places: items });
            });
          },
        }}
        wrapperStyle={{
          position: "relative",
          display: "inline-block",
          width: "100%",
        }}
        value={this.props.value?.name}
        items={this.state.places.slice(0, 5)}
        getItemValue={(item) =>
          ["displayName", "country"].map((field) => item[field]).join(", ")
        }
        onSelect={(value, item) => {
          this.props.onChange({
              name: value,
              geonamesId: item.geonamesId
          });
          console.log("Calling props.handleHomeCityGeonamesId", item)
          this.props.handleHomeCityGeonamesId(item.geonamesId);
          this.setState({ selectedValue: value, value, places: [item] });
        }}
        onChange={(event, value) => {
          this.props.onChange({
              name: value,
              geonamesId: ""
          });
          this.props.handleHomeCityGeonamesId("");
          this.setState({ value, selectedValue: null });
          const fetchIndex = this.state.latestFetchIndex + 1;
          this.setState({ latestFetchIndex: fetchIndex })
          console.log("Starting fetchIndex " + fetchIndex);
          fetchAutocomplete(value).then(items => {
            console.log("Completed fetchIndex " + fetchIndex
                + " (latest: " + this.state.latestFetchIndex + ")");
            if (fetchIndex === this.state.latestFetchIndex) {
                // If false, it means this request finished after a more recent one
                // and its result can be discarded.
                this.setState({places: items});
            }
          });
        }}
        renderMenu={(children) => (
          <div className={`${styles.menu} ${this.props.styles_menu}`}>
            {children}
          </div>
        )}
        renderItem={(item, isHighlighted) => (
          <div
          style={{ background: isHighlighted ? 'lightgray' : 'white' }}
            className={`${styles.item} ${
              isHighlighted ? styles.itemHighlighted : ""
            }`}
            key={item.geonamesId}
          >
            {["displayName", "country"].map((field) => item[field]).join(", ")}
          </div>
        )}
      />
    );
  }
}

export default PlacesAutocomplete;
