import React from "react";
import { Link, withRouter } from "react-router-dom";

import logoWhite from "../../assets/images/Logo_white_plain.svg";

import styles from "./styles.module.css";

class Step extends React.Component {
  render() {
    return (
      <div
        // className={this.props.className}
        style={{
          backgroundImage: `url(${this.props.backgroundImage})`,
        }}
        className={`flex justify-center top-0 left-0 right-0 bottom-0 overflow-auto ${
          this.props.classNames
        }`}
      >
        <div
          className={`${styles.step_second_wrapper} ${this.props.className}`}
        >
          <img src={logoWhite} className={styles.logo} alt="img" />
          {this.props.children}
          <p className={styles.footer}>
            <Link to="/terms-and-conditions">Terms and conditions</Link>,{" "}
            <Link to="/privacy-notice">privacy notice</Link>
            <br /> and <Link to="/cookie-statement">cookie statement</Link>
          </p>
        </div>
      </div>
    );
  }
}

Step = withRouter(Step);

export default Step;
