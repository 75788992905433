import React from "react";

import item1 from "../../../../assets/images/item_5_1.png";
import item2 from "../../../../assets/images/item_5_2.png";
import item3 from "../../../../assets/images/bg_5_2.png";
import bgTheStudioSpace from "../../../../assets/images/bg_5_1.png";

import styles from "./styles.module.css";
import TheStudioSpaceMobile from "./mobile";

const TheStudioSpaceDesktop = () => {
  return (
    <div className={styles.the_studio_space}>
      <div className={styles.wrapper_left}>
        <div className={styles.wrap_text_left}>
          <h1 className={styles.title}>The Studio Space</h1>
          <p className={styles.desc}>Model search and scouting events</p>
          <br />
          <p className={styles.desc}>Career coaching and training workshops</p>
        </div>
        <img src={item1} alt="" />
      </div>
      <div className={styles.wrapper_right}>
        <div className={styles.wrapper_text_right}>
          <img src={bgTheStudioSpace} alt="" />
        </div>
        <p className={styles.desc_block_left}>
          We can provide the talent, creatives
          <br /> and shoot location for a fixed fee
        </p>
        <img src={item3} className={styles.tablet_block_3_swap} alt="" />
        <div className={styles.block_3} />
        <div className={styles.block_right}>
          <div className={styles.content_block_right}>
            <div className={styles.block}>
              <div className={styles.block_1}>
                BRAND LAUNCHES AND CAMPAIGN PARTIES
              </div>
              <div className={styles.block_2}>
                Exclusive networking
                <br /> events for members
              </div>

              <div className={styles.block_4}>
                SCHMODEL STUDIOS CAN BE USED BY TALENT, BRANDS AND CREATIVES
                WHEN NOT IN PRODUCTION
              </div>
            </div>
            <img src={item2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default class TheStudioSpace extends React.Component {
  render() {
    return (
      <>
        <TheStudioSpaceDesktop />
        <TheStudioSpaceMobile />
      </>
    );
  }
}
