import React from "react";

import StateMachine from "src/components/utils/StateMachine";

import AccessInstagram from "./AccessInstagram";

export default class StepFourth extends React.Component {
  render() {
    return (
      <React.Fragment>
        <StateMachine.State is="register.instagram.connectAccount">
          <AccessInstagram />
        </StateMachine.State>
      </React.Fragment>
    );
  }
}
