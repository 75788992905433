import React from 'react';

import dubaiImage from '../../../../assets/images/dubai_text.png';
import bikiniImg from '../../../../assets/images/bikini_image.png';
import guywithcamera from '../../../../assets/images/guywithcamera.png';
import openForBusiness from '../../../../assets/images/open_for_business.png';
import makeUp from '../../../../assets/images/makeup.png';

import styles from './styles.module.css';
import { BrowserView, MobileView } from 'react-device-detect';

export default class OpenForBusiness extends React.Component {
  render() {
    return (
      <>
        {/* <BrowserView> */}
        <div className={styles.is_desktop}>
          <div className={styles.open_for_business}>
            <img src={openForBusiness} className={styles.warning} />
            <div className={styles.container}>
              <div className={styles.block_left}>
                <img src={dubaiImage} />
                <div className={styles.content_box}>
                  <p className={styles.content_block_left_first}>
                    We curate creative professionals - photographers, creative
                    directors, hair & makeup artists and production managers -
                    to create unique and local content for brands
                  </p>
                  <p className={styles.content_block_left_second}>
                    Find the right talent and creatives
                    <br /> for your brand.
                  </p>
                  <button
                    className={styles.button}
                    onClick={this.props.handleOpenBrandSignup}
                  >
                    BRAND SIGN-UP
                  </button>
                </div>
              </div>
              <div className={styles.block_right}>
                <div className={styles.content_block_right_first}>
                  <div>
                    <p className={styles.title}>Currently in Dubai? </p>
                    <p className={styles.sub_title}>
                      Sign up and keep in touch.
                      <br /> Get job offers and collaborate with other creatives
                      and brands
                    </p>
                    <button
                      className={styles.button}
                      onClick={this.props.handleScrollBottom}
                    >
                      TALENT SIGN-UP
                    </button>
                  </div>
                  <img src={bikiniImg} />
                </div>
              </div>
            </div>
            <div className={styles.content_block_right_second}>
              <img src={guywithcamera} />
              <div className={styles.wrap_block}>
                <div className={styles.block_1}>
                  WE ATTRACT INTERNATIONAL MODELS INFLUENCERS AND DIGITAL
                  CONTENT CREATORS TO DUBAI
                </div>
                <div className={styles.block_2}>
                  We create an environment
                  <br /> of inclusion, solidarity
                  <br /> and safety by providing a<br /> "studio home" for our
                  talent
                  <br /> and clients to come together.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </BrowserView> */}
        {/* <MobileView> */}
        <div className={styles.is_mobile}>
          <div className={styles.content_1}>
            <img src={guywithcamera} />
            <div className={styles.content_box}>
              <p className={styles.content_block_left_first}>
                We curate creative professionals - photographers, creative
                directors, hair & makeup artists and production managers - to
                create unique and local content for brands
              </p>
              <p className={styles.content_block_left_second}>
                Find the right talent and creatives
                <br /> for your brand.
              </p>
            </div>
          </div>
          <div className={styles.content_2}>
            <div className={styles.bikiniImg} />
            <img src={dubaiImage} className={styles.dubaiImage} />
            <img src={openForBusiness} className={styles.openForBusiness} />
            <div className={styles.content_box_2}>
              <p className={styles.content_block_left_first}>
                Currently in Dubai?
              </p>
              <p className={styles.content_block_left_second}>
                Sign up and keep in touch.
                <br /> Get job offers and collaborate with other creatives and
                brands
              </p>
            </div>
          </div>
          <div className={styles.content_3}>
            <img src={makeUp} className={styles.makeUp} />
            <div className={styles.content_box_3}>
              <p className={styles.content_block_1}>
                WE ATTRACT INTERNATIONAL MODELS INFLUENCERS AND DIGITAL CONTENT
                CREATORS TO DUBAI
              </p>
              <p className={styles.content_block_2}>
                We create an environment <br /> of inclusion, solidarity <br />
                and safety by providing a <br />
                "studio home" for our talent <br /> and clients to come
                together.
              </p>
            </div>
          </div>
        </div>
        {/* </MobileView> */}
      </>
    );
  }
}
