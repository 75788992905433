import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    _id
    firstName
    lastName
    email
    phone {
      number
    }
    referrer
    instagramHandle
    instagramToken
    images
    homeCity {
       geonamesId
       name
    }
    locations
    status
    skills
  }
`;

export const UPDATE_USER_GENERAL_INFORMATION = gql`
  mutation updateUserGeneralInformation($input: UserGeneralInformationInput) {
    updateUserGeneralInformation(input: $input) {
      ...UserFragment
      auth {
        token
      }      
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_SKILLS = gql`
  mutation updateUserSkills($input: UserSkillsInput) {
    updateUserSkills(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_LOCATIONS = gql`
  mutation updateUserLocations($input: UserLocationsInput) {
    updateUserLocations(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_PHOTOS = gql`
  mutation updateUserPhotos($input: UserPhotosInput) {
    updateUserPhotos(input: $input) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const SEND_VERIFY_CODE = gql`
  mutation sendVerifyCode {
    sendVerifyCode {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const SEND_VERIFY_EMAIL = gql`
  mutation sendVerifyEmail {
    sendVerifyEmail {
      _id
    }
  }
`;

export const CHECK_VERIFY_CODE = gql`
  mutation checkVerifyCode($code: String) {
    checkVerifyCode(code: $code) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const CHECK_VERIFY_EMAIL_CODE = gql`
  mutation checkEmailVerifyCode($code: String) {
    checkEmailVerifyCode(code: $code) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const EXCHANGE_TOKEN = gql`
  mutation exchangeToken($code: String) {
    exchangeToken(code: $code) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
