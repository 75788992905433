import React, { useEffect, useRef, useState } from "react";
import { FaInstagram } from "react-icons/fa";
import ReactCodeInput from "react-code-input";
import cn from "classnames";
import debounce from "debounce";
import { Field } from "redux-form";

import camerawoman from "../../../assets/images/camerawoman.png";
import camerawomanMobile from "../../../assets/images/camerawoman_mobile.png";

import Step from "../Step";
import InputGroup from "../../utils/InputGroup";
import { Checkbox } from "../../utils/Checkbox";
import WithUserForm from "../../Register/WithUserForm";
import { ReactComponent as Warning } from "src/assets/icons/warning.svg";
import { ReactComponent as InstagramFollowButton} from "src/assets/schmodelstudiosbutton.svg"

import styles from "../styles.module.css";

class CodeInput extends React.Component {
  codeRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.resetCode && !prevProps.resetCode) {
      const currentCodeRef = this.codeRef.current;
      if (currentCodeRef) {
        for (let index = 0; index < currentCodeRef.textInput.length; index++) {
          currentCodeRef.textInput[index].value = "";
        }
        const stateName = this.props.refKey === 1 ? "resetSmsCode" : "resetEmailCode";
        this.props.handleStateChange(stateName);
      }
    }
  }

  render() {
    const { input, touched, error, autoFocus } = this.props;

    const options = {
      className: cn(styles.CodeInput, { [styles.InputError]: touched && error }),
      inputStyle: {
        borderRadius: "10px",
        height: "45px",
        width: "42px",
        background: "#1A1A1A",
        border: "1px solid rgba(255, 255, 255, 0.17)",
        outline: "none",
        margin: "0 20px",
      },
    };

    return (
        <ReactCodeInput
            ref={this.codeRef}
            type="number"
            fields={3}
            autoFocus={autoFocus}
            {...options}
            {...this.props}
            {...input}
        />
    );
  }
}
class Form extends React.Component {
  state = { codeSent: false, resetSmsCode: false, resetEmailCode: false };

  componentDidMount() {
    this.props.sendVerifyCode();
    this.props.sendVerifyEmail();
  }

  resetState = (stateName) => {
    this.setState({[`${stateName}`]: false})
  };

  render() {
    const {
      form: {
        handleSubmit,
        reset,
        resetStatusCodes,
        isVerifyCode,
        isVerifyEmailCode,
        verifyCodeError,
        verifyEmailCodeError,
      },
      machine,
    } = this.props;
    const sendVerifyCode = this.props.sendVerifyCode;
    const sendVerifyEmail = this.props.sendVerifyEmail;
    return (
        <Step backgroundImage={
          window.innerWidth > 500
              ? camerawoman
              : camerawomanMobile
        }
              classNames={styles.step_confirmation}>
          <form onSubmit={handleSubmit}>
            <p className={`${styles.title} ${styles.title_confirmation}`}>
              Congrats, you genius!
            </p>
            <p className={`${styles.sub_title} ${styles.sub_title_confirmation}`}>
              We sent you a text message <br />
              and an email. Please enter both <br />
              verification codes below and <br />
              follow our Instagram account <br />
              to complete your signup.
            </p>
            <button
                className={(this.state.codeSent || (isVerifyCode && isVerifyEmailCode)) ? styles.resend_verify_disable : styles.resend_verify}
                onClick={() => {
                  reset();
                  resetStatusCodes();
                  sendVerifyCode();
                  sendVerifyEmail();
                  this.setState({ codeSent: true, resetSmsCode: true, resetEmailCode: true});
                  setTimeout(() => {
                    this.setState({ codeSent: false });
                  }, 3000);
                }}
                type="button"
                disabled={this.state.codeSent || (isVerifyCode && isVerifyEmailCode)}
            >
              Resend Verification codes
            </button>
            <div className={styles.wrap_sms_verify}>
              <p>SMS verification code</p>
              <div
                  className={`${styles.sms_verify} ${verifyCodeError &&
                  !isVerifyCode &&
                  styles.danger_color} ${isVerifyCode &&
                  styles.sms_verify_disable}`}
              >
                <Field
                    normalize={(value, previousValue) =>
                        (value && value.length) > 3 ? previousValue : value
                    }
                    showValidatedCheck={false}
                    name="code"
                    resetCode={this.state.resetSmsCode}
                    refKey={1}
                    handleStateChange={this.resetState}
                    component={CodeInput}
                    disabled={isVerifyCode}
                />
                <InputGroup
                    classNames={`${styles.sms_checked} ${
                        styles.checked_confirmation
                    } ${styles.no_margin}`}
                >
                  <Checkbox
                      isChecked={isVerifyCode}
                      disabled={true}
                      className={!isVerifyCode && styles.display_none}
                  />
                </InputGroup>
                {verifyCodeError && !isVerifyCode ? (
                    <div
                        className={`${styles.error_form} ${
                            styles.error_form_stepconfirmation
                        }`}
                    >
                  <span className={styles.warning_icon}>
                    <Warning />
                  </span>
                      <p className={styles.warning_msg}>
                        Your verification code failed. Please try again
                      </p>
                    </div>
                ) : null}
              </div>
            </div>

            <div className={styles.wrap_email_verify}>
              <p>Email verification code</p>
              <div
                  className={`${styles.email_verify} ${verifyEmailCodeError &&
                  !isVerifyEmailCode &&
                  styles.danger_color} ${isVerifyEmailCode &&
                  styles.email_verify_disable}`}
              >
                <Field
                    normalize={(value, previousValue) =>
                        (value && value.length) > 3 ? previousValue : value
                    }
                    component={CodeInput}
                    name="emailCode"
                    refKey={2}
                    resetCode={this.state.resetEmailCode}
                    showValidatedCheck={false}
                    handleStateChange={this.resetState}
                    autoFocus={false}
                    disabled={isVerifyEmailCode}
                />
                <InputGroup
                    classNames={`${styles.sms_checked} ${
                        styles.checked_confirmation
                    } ${styles.no_margin}`}
                >
                  <Checkbox
                      isChecked={isVerifyEmailCode}
                      disabled={true}
                      className={!isVerifyEmailCode && styles.display_none}
                  />
                </InputGroup>
                {verifyEmailCodeError && !isVerifyEmailCode ? (
                    <div
                        className={`${styles.error_form} ${
                            styles.error_form_stepconfirmation
                        }`}
                    >
                  <span className={styles.warning_icon}>
                    <Warning />
                  </span>
                      <p className={styles.warning_msg}>
                        Your verification code failed. Please try again
                      </p>
                    </div>
                ) : null}
              </div>
            </div>
            <p>
              Follow our Instagram account to <br />
              complete your registration
            </p>
            <a
                href="https://www.instagram.com/schmodel.studios/"
                className={`${styles.wrap_instagram} ${
                    styles.wrap_confirm_instagram
                } ${styles.follow_instagram}`}
            >
              <InstagramFollowButton />
            </a>
          </form>
        </Step>
    );
  }
}

class StepConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.asyncValidate = this.asyncValidate.bind(this);
    this.state = {
      codeSent: false,
      isVerifyCode: false,
      isVerifyEmailCode: false,
      verifyCodeError: false,
      verifyEmailCodeError: false,
    };
  }

  resetStatusCodes = () => {
    this.setState({verifyCodeError: false, verifyEmailCodeError: false, isVerifyCode: false, isVerifyEmailCode: false});
  };

  async asyncValidate(values, _, props) {
    if (!this.state.isVerifyCode) {
      if (values.code && values.code.length === 3) {
        const mutation = await props.checkVerifyCode({
          variables: { code: values.code },
        });
        if (mutation.data.checkVerifyCode) {
          this.setState({ isVerifyCode: true });
        } else {
          this.setState({ verifyCodeError: true });
        }
      } else {
        this.setState({ verifyCodeError: false });
      }
    }

    if (!this.state.isVerifyEmailCode) {
      if (values.emailCode && values.emailCode.length === 3) {
        const mutation = await props.checkEmailVerifyCode({
          variables: { code: values.emailCode },
        });
        if (mutation.data.checkEmailVerifyCode) {
          this.setState({ isVerifyEmailCode: true });
        } else {
          this.setState({ verifyEmailCodeError: true });
        }
      } else {
        this.setState({ verifyEmailCodeError: false });
      }
    }

    if (this.state.isVerifyCode && this.state.isVerifyEmailCode) {
      props.done();
    }
  }

  render() {
    return (
        <WithUserForm
            asyncValidate={this.asyncValidate}
            asyncChangeFields={["code", "emailCode"]}
            isVerifyCode={this.state.isVerifyCode}
            isVerifyEmailCode={this.state.isVerifyEmailCode}
            verifyCodeError={this.state.verifyCodeError}
            verifyEmailCodeError={this.state.verifyEmailCodeError}
            resetStatusCodes={this.resetStatusCodes}
            render={(props) => <Form {...props} />}
        />
    );
  }
}

export default StepConfirmation;
